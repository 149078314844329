<!--
  服务类型详情
    + 可以编辑服务类型的字段
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="字段编辑" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <!-- 表单组件 -->
        <custom-form
          :formModel="fieldList"
          :formData="formData">
          <custom-form-item
            v-for="item in fieldList"
            :key="item.column"
            :item="item"
            :value.sync="formData[item.column]">
            <div
              v-if="!item.isFixed"
              class="item_icon_box tw-ml-10px"
              @click="deleteItem(item)">
              <i class="iconfont iconshanchu"></i>
            </div>
          </custom-form-item>
        </custom-form>

        <div class="add_btn_box">
          <div class="tw-mt-30px">
            <el-button size="small" @click="openAddFieldDialog"><i class="el-icon-plus"></i> 新增字段</el-button>
          </div>

          <div class="tw-mt-40px">
            <el-button size="small" type="primary" :loading="btnLoading" @click="updateCustomForm">保存</el-button>
          </div>
        </div>

        <!-- 添加字段的弹窗 -->
        <add-field
          :visible.sync="addFieldDialogVisible"
          @addField="addField">
        </add-field>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import addField from './components/add-field'
import customForm from '@/components/custom-form/index'
import customFormItem from '@/components/custom-form/custom-form-item'
export default {
  data () {
    return {
      btnLoading: false,
      // 页面loading
      loading: false,
      // 添加字段的dialog
      addFieldDialogVisible: false,
      // 字段数组
      fieldList: [],
      // 表单数据
      formData: {}
    }
  },
  props: {
    // 服务类型的表单Id
    formId: {
      type: String,
      default: ''
    }
  },
  components: {
    addField,
    customForm,
    customFormItem
  },
  created () {
    // 获取表单的详情
    this.getFormDetail()
  },
  methods: {
    // 获取表单的详情
    getFormDetail () {
      this.loading = true
      api.getFormDetail(this.formId).then(res => {
        if (res.data.code === 0) {
          this.fieldList = res.data.data.fields
          this.formData = res.data.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },

    // 更新自定义表单
    updateCustomForm () {
      this.btnLoading = true
      api.updateCustomForm({
        formId: this.formId,
        fields: this.fieldList
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('保存成功')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    },

    // 删除一个项
    deleteItem (item) {
      const index = this.fieldList.findIndex(fItem => {
        return fItem.column === item.column
      })
      if (index !== -1) {
        this.fieldList.splice(index, 1)
        this.$delete(this.formData, item.column)
      }
    },
    // 打开新增字段的弹窗
    openAddFieldDialog () {
      this.addFieldDialogVisible = true
    },
    // 添加字段
    addField (data) {
      this.fieldList.push(data)
      this.$set(this.formData, data.column, null)
    }
  }
}
</script>
<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 602px;
      margin: 0 auto;

      .add_btn_box {
        padding-left: 180px;
      }

      .item_icon_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #DCDFE6;
        border-radius: 2px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          background: #fff;
          border-color: #3473E6;
          color: #3473E6;
        }
      }
    }
  }
}
</style>
